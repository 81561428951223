@use '../utilities/variables' as v;

@mixin title {
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.25rem;
  color: v.$brand-orange;
}

@mixin tablet {
  @media (min-width: #{v.$tablet-width}) and (max-width: #{v.$desktop-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{v.$desktop-width}) {
    @content;
  }
}
