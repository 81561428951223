@use '../utilities/variables' as v;

.button {
  all: unset;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 12px;
  cursor: pointer;
  width: fit-content;

  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
}

.button--small {
  font-size: 1rem;
}

.button--medium {
  font-size: 1rem;
  padding: 8px 48px;
}

.button--large {
  font-size: 2rem;
}

.button--primary {
  background-color: v.$brand-blue;
  color: v.$brand-white;
}

.button--primary:hover {
  background-color: v.$brand-blue-secondary;
}

.button--primary:focus {
  outline: none;
  box-shadow: 0 0 0 3px v.$brand-blue-outline;
}

.button--primary:active {
  background-color: v.$brand-blue-active;
}

//
.button--secondary {
  background-color: v.$brand-orange;
  color: v.$brand-white;
}

.button--secondary:hover {
  background-color: v.$brand-orange-secondary;
}

.button--secondary:focus {
  outline: none;
  box-shadow: 0 0 0 3px v.$brand-orange-outline;
}

.button--secondary:active {
  background-color: v.$brand-orange-active;
}

.button[aria-disabled='true'] {
  pointer-events: none;
  // cursor: not-allowed;
  opacity: 0.5;
}
