@use '../utilities/variables' as v;

.price {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &__amount {
    color: v.$text-secondary;
    font-weight: 700;
    font-size: 2rem;
    line-height: 2rem;
  }

  &__disclaimer {
    font-size: 1rem;
    font-weight: 400;
    font-style: italic;
    line-height: 1.4rem;

    &__date {
      color: v.$text-secondary;
      font-weight: 700;
    }
  }

  &__fqa-link {
    color: v.$brand-orange;
    font-weight: bold;
  }
}
