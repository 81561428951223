@use '../utilities/mixins';

.checkout {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px 40px;
  background-image: url(../assets/images/pattern_background.svg);
  background-size: cover;
  height: 100vh;

  &__photo-grid {
    display: none;

    @include mixins.desktop {
      display: revert;
      flex: 1;
    }
  }

  &__payment__wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__payment {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include mixins.tablet {
      margin: 0 auto;
      max-width: 500px;
    }

    @include mixins.desktop {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 16px;
  }

  &__message {
    background-color: white;
  }

  &__stripe {
    text-align: center;
    a {
      all: unset;
      cursor: pointer;
    }
    span {
      font-weight: bold;
    }
  }

  @include mixins.desktop {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 30px 16px;
    height: 100vh;
    padding: 0;
    padding-left: 24px;
  }
}
