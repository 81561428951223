@use '../utilities/variables' as v;
@use '../utilities/mixins';

.media-description {
  display: flex;
  flex-direction: column;
  gap: 22px;

  &__title {
    @include mixins.title;
  }

  &__annotation {
    color: v.$annotation;
    font-size: 0.8rem;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    list-style-type: none;
    padding: 0;

    &__item {
      display: flex;
      gap: 16px;
      align-items: center;
      font-weight: 700;

      &__count {
        display: flex;
        gap: 16px;
        color: v.$gray-700;
        font-size: 1.125rem;
      }

      svg {
        height: 32px;
        width: 32px;
      }

      &__category {
        color: v.$brand-orange;
        font-size: 1.25rem;
      }
    }
  }
}
