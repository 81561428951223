@use '../utilities/mixins';

.payment-success {
  display: flex;
  flex-direction: column;
  padding: 30px 16px 80px 16px;
  gap: 45px;
  background-image: url(../assets/images/pattern_background.svg);
  background-size: cover;
  height: 100%;

  &__photo-grid {
    @include mixins.desktop {
      flex: 1;
    }
  }

  &__content__wrapper {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    gap: 24px;
  }

  &__content {
    display: flex;
    flex-direction: column;

    @include mixins.tablet {
      margin: 0 auto;
      max-width: 500px;
    }

    @include mixins.desktop {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  @include mixins.desktop {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 30px 16px;
    height: 100vh;
    padding: 0;
    padding-left: 24px;
  }
}
