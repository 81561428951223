@font-face {
  font-family: 'proximanova';
  src: local('proximanova'),
    url('./assets/fonts/proximanova-regular-webfont.eot');
  src: local('proximanova'),
    url('./assets/fonts/proximanova-regular-webfont.woff2') format('woff2'),
    url('./assets/fonts/proximanova-regular-webfont.woff') format('woff'),
    url('./assets/fonts/proximanova-regular-webfont.svg') format('svg'),
    url('./assets/fonts/proximanova-regular-webfont.ttf') format('truetype'); /* Legacy iOS */
  font-weight: normal;
}

@font-face {
  font-family: 'proximanova';
  src: local('proximanova'), url('./assets/fonts/proximanova-bold-webfont.eot');
  src: local('proximanova'),
    url('./assets/fonts/proximanova-bold-webfont.woff2') format('woff2'),
    url('./assets/fonts/proximanova-bold-webfont.woff') format('woff'),
    url('./assets/fonts/proximanova-bold-webfont.svg') format('svg'),
    url('./assets/fonts/proximanova-bold-webfont.ttf') format('truetype'); /* Legacy iOS */
  font-weight: bold;
}

/* Css reset by https://www.joshwcomeau.com/css/custom-css-reset/ */
/*
  1. Use a more-intuitive box-sizing model.
*/
*,
*::before,
*::after {
  box-sizing: border-box;
}
/*
  2. Remove default margin
*/
* {
  margin: 0;
}
/*
  3. Allow percentage-based heights in the application
*/
html,
body {
  height: 100%;
}

#root {
  height: 100%;
}
/*
  Typographic tweaks!
  4. Add accessible line-height
  5. Improve text rendering
*/
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  font-family: proximanova, Arial, serif;
}
/*
  6. Improve media defaults
*/
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}
/*
  7. Remove built-in form typography styles
*/
input,
button,
textarea,
select {
  font: inherit;
}
/*
  8. Avoid text overflows
*/
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}
/*
  9. Create a root stacking context
*/
#root,
#__next {
  isolation: isolate;
}
