// Brand colors
$brand-orange: #ff8b47;
$brand-orange-secondary: #a92a07;
$brand-orange-outline: #ed866a;
$brand-orange-active: #7e1b00;
$brand-blue: hsla(210, 83%, 64%, 1);
$brand-blue-secondary: hsla(210, 77%, 30%, 1);
$brand-blue-outline: lightskyblue;
$brand-blue-active: hsla(210, 77%, 20%, 1);
$brand-white: #ffffff;
$brand-disabled: hsla(0, 0%, 90%, 1);

$border-color: hsla(0, 0%, 62%, 1);

// Error colors
$brand-error: hsla(4, 90%, 47%, 1);

// Text colors
$text-primary: hsla(0, 0%, 0%, 0.87);
$text-secondary: hsla(0, 0%, 0%, 0.6);
$gray-700: hsla(217, 23%, 27%, 1);

// Box shadows colors
$box-shadow-image-desktop: rgba(0, 0, 0, 0.75);
$box-shadow-image-mobile: rgba(0, 0, 0, 0.53);

// Breakpoints
$tablet-width: 550px;
$desktop-width: 1100px;

$annotation: #c4c4c4;