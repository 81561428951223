@use '../utilities/variables' as v;
@use '../utilities/mixins';

.information {
  display: flex;
  flex-direction: column;
  gap: 24px;

  &__title {
    @include mixins.title;
  }

  &__description {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4rem;
    color: v.$text-primary;

    @include mixins.desktop {
      max-width: 447px;
    }
  }
}
