@use '../utilities/variables' as v;
@use '../utilities/mixins';

.photo-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(1, 1fr);
  grid-gap: 8px 8px;

  &__image {
    min-width: 100%;
    min-height: 64px;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in;

    &:hover {
      transform: scale(1.3);
      box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2),
        0px 10px 14px 1px rgba(0, 0, 0, 0.14),
        0px 4px 18px 3px rgba(0, 0, 0, 0.12);
    }
  }

  @include mixins.desktop {
    &__image {
      min-width: 100%;
      min-height: 160px;

      &:hover {
        box-shadow: -1px 14px 107px 24px v.$box-shadow-image-desktop;
        -webkit-box-shadow: -1px 14px 107px 24px v.$box-shadow-image-desktop;
        -moz-box-shadow: -1px 14px 107px 24px v.$box-shadow-image-desktop;
      }
    }
  }

  @include mixins.tablet {
    &__image {
      min-width: 100px;
      min-height: 60px;
    }
  }
}
