@use '../utilities/variables' as v;
@use '../utilities/mixins';

.download {
  display: flex;
  flex-direction: column;
  padding: 30px 16px 80px 16px;
  gap: 45px;
  background-image: url(../assets/images/pattern_background.svg);
  background-size: cover;

  &__photo-grid {
    @include mixins.desktop {
      flex: 1;
    }
  }

  &__content__wrapper {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    gap: 24px;
  }

  &__expired {
    background-color: v.$brand-disabled;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 40vh;
    margin-left: -16px;
    margin-right: -16px;
    margin-top: -30px;

    @include mixins.desktop {
      height: 100vh;
      width: 100%;
      margin-left: -24px;
      margin-bottom: -30px;
    }
  }

  &__contact {
    font-size: 0.875rem;
    color: v.$text-secondary;
    a {
      all: unset;
      cursor: pointer;
      text-decoration: underline;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;

    @include mixins.tablet {
      margin: 0 auto;
      max-width: 500px;
    }

    @include mixins.desktop {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  @include mixins.desktop {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 30px 16px;
    height: 100vh;
    padding: 0;
    padding-left: 24px;
  }

  @include mixins.tablet {
    height: 100%;
  }
}
