@use '../utilities/variables' as v;
@use '../utilities/mixins';

.error {
  background-image: url(../assets/images/pattern_background.svg);
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  &__logo {
    height: 122px;
    width: 200px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__title {
    display: flex;
    align-items: center;
  }

  &__message {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    text-align: center;
    padding: 24px;
    h3 {
      padding-left: 16px;
      font-size: 1.5rem;
      color: v.$brand-error;
    }

    h5 {
      font-size: 1.25rem;
      color: v.$text-secondary;
    }

    @include mixins.desktop {
      align-items: flex-start;
      text-align: revert;
      max-width: 500px;
    }
  }

  &__contact {
    font-size: 0.875rem;
    color: v.$text-secondary;
    a {
      all: unset;
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
