@use '../utilities/variables' as v;

.header {
  display: flex;
  align-items: center;
  gap: 16px;

  &__logo {
    height: 122px;
    width: 200px;
  }

  &__description {
    line-height: 1.25rem;
    font-weight: 700;
    font-size: 1.25rem;
    color: v.$text-primary;
    border-left: 2px solid v.$border-color;
    padding-left: 16px;
  }
}
